import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Props as BenefitsItemProps } from 'app/components/Benefits/Item'
import { Button } from 'app/components/Common/Button'
import { Languages } from 'app/components/Common/Header/Languages'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { Props as LanguageItemProps } from './LanguageItem'
import { ModalClose } from './ModalClose'
import { ModalIBE } from './ModalIBE'
import { Navigation } from './Navigation'

export interface Props {
  address?: string
  backgroundImage?: { [key: string]: any }
  benefitsDescription?: string
  benefitsLabel?: string
  benefitsChecklist?: BenefitsItemProps[]
  country?: string
  email?: string
  fax?: string
  IBEID?: number
  phone?: string
  postalCode?: string
  languageCode: string
  languagePrefix: string | null
  languagesList: LanguageItemProps[]
  logo?: string
  logoSticky?: string
  menuItems: MenuItemProps[]
  pageID?: string
  locality?: string
  region?: string
  siteName?: string
  whatsapp?: string
  variant?: Variant
}

export const Header = memo(function Header({
  address,
  backgroundImage,
  benefitsDescription,
  benefitsLabel,
  benefitsChecklist,
  country,
  email,
  fax,
  IBEID,
  phone,
  postalCode,
  languageCode,
  languagePrefix,
  languagesList,
  logo,
  logoSticky,
  menuItems,
  pageID,
  locality,
  region,
  siteName,
  whatsapp,
  variant = 'default',
}: Props) {
  const languages = languagesList.filter((t) => t.pageID === pageID)

  const [scroll, setScroll] = useState(false)
  const [navigationStatus, setNavigationStatus] = useState(false)
  const [modalIBEStatus, setModalIBEStatus] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    if (window.innerWidth > 1023) {
      disableScroll.off()
    } else {
      document.body.style.overflow = 'auto'
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head
          className={scroll || variant === 'compact' ? 'fixed' : ''}
          dial={6}
          row
          variant={variant}
        >
          <Hamburger
            className="hamburger-menu"
            onClick={() => {
              if (!navigationStatus) {
                setNavigationStatus(true)
                if (window.innerWidth > 1023) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setNavigationStatus(false)
              }
            }}
          >
            <div />
            <HambugerLabel className="hamburger-label">
              {useVocabularyData('menu', languageCode)}
            </HambugerLabel>
          </Hamburger>
          <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
            {logoSticky ? (
              <Media lessThan="ipadHorizontal">
                <Logo
                  className="logo"
                  src={logoSticky}
                  alt={siteName}
                  width="180"
                  height="90"
                />
              </Media>
            ) : null}
            {logo ? (
              <Media greaterThanOrEqual="ipadHorizontal">
                <Logo
                  className="logo"
                  src={scroll || variant === 'compact' ? logoSticky : logo}
                  alt={siteName}
                  width="180"
                  height="90"
                />
              </Media>
            ) : null}
          </Link>
          {phone ? (
            <Phone
              className="header-phone"
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Mobile Phone',
                    })
                }
              }}
            >
              {phone}
            </Phone>
          ) : null}
          <Languages languagePrefix={languagePrefix} languages={languages} />
          <Media greaterThan="tabletMedium">
            <CTA
              arrow
              label={useVocabularyData('book', languageCode)}
              onClick={() => {
                if (!modalIBEStatus) {
                  setModalIBEStatus(true)
                  disableScroll.on()
                } else {
                  setModalIBEStatus(false)
                  disableScroll.off()
                }
              }}
            />
          </Media>
          <Media between={['ipadVertical', 'ipadHorizontal']}>
            <CTA
              arrow
              URL={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
                languagePrefix ? languagePrefix : 'it'
              }`}
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
            />
          </Media>
        </Head>
        <Modal className={navigationStatus ? 'open' : ''}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              if (window.innerWidth > 1023) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setNavigationStatus(false)
            }}
          />
          <Navigation
            address={address}
            backgroundImage={backgroundImage}
            country={country}
            email={email}
            fax={fax}
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix || 'it'}
            logo={logo}
            locality={locality}
            menuItems={menuItems}
            navigationStatus={navigationStatus}
            phone={phone}
            postalCode={postalCode}
            region={region}
            siteName={siteName}
            whatsapp={whatsapp}
          />
          <Languages
            languagePrefix={languagePrefix}
            languages={languages}
            variant="modal"
          />
        </Modal>
        <Media greaterThanOrEqual="ipadVertical">
          <Modal className={modalIBEStatus ? 'open' : ''}>
            <ModalClose
              languageCode={languageCode}
              onClick={() => {
                disableScroll.off()
                setModalIBEStatus(false)
              }}
            />
            <ModalIBE
              IBEID={IBEID}
              languageCode={languageCode}
              languagePrefix={languagePrefix || 'it'}
              benefitsDescription={benefitsDescription}
              benefitsLabel={benefitsLabel}
              benefitsChecklist={benefitsChecklist}
              modalStatus={modalIBEStatus}
            />
          </Modal>
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  box-shadow: 0px 10px 20px rgba(24, 31, 29, 0.04);
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;

  @media (min-width: 1024px) {
    padding: 0.8rem 2.25rem;
  }

  .logo {
    height: 50px;
    margin-top: 0.5625rem;

    @media (min-width: 1024px) {
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0;
    }
  }
  .hamburger-menu {
    &:before,
    &:after {
      background: ${theme.colors.variants.neutralDark4};
    }
    > div {
      background: ${theme.colors.variants.neutralDark4};
    }
  }
  .hamburger-label {
    color: ${theme.colors.variants.neutralDark4};
  }
  .header-phone {
    color: ${theme.colors.variants.neutralDark4};
  }
  .header-langs {
    > div {
      &:first-of-type {
        &:before {
          opacity: 0;
        }
      }
      &:last-of-type {
        width: calc(100% + 1rem);
        background: ${theme.colors.variants.neutralLight4};
        top: 2.5rem;
      }
      a {
        color: ${theme.colors.variants.neutralDark4};
      }
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  margin-top: 1.5rem;
  padding: 0 2.25rem;
  position: relative;
  &.fixed {
    ${HeaderFixedStyle}
  }

  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return css`
          &.fixed {
            animation: fadeInTop 0.3s;
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    ${HeaderFixedStyle}
    animation: none !important;
    padding: 0 1.5rem;
    .logo {
      height: 2.975rem !important;
      margin-top: 0.75rem !important;
    }
  }

  @media (max-width: 767px) {
    .header-langs {
      margin-right: 0 !important;
    }
  }

  @keyframes fadeInTop {
    0% {
      top: -3.75rem;
    }
    100% {
      top: 0;
    }
  }
`

const Logo = styled.img`
  width: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Hamburger = styled.div`
  cursor: pointer;
  width: 36px;
  margin-right: auto;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 2px;
  }
  &:hover {
    > div {
      width: 100%;
    }
  }
  > div {
    width: 17px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 2px;
    margin: 12px auto;
    transition: 0.3s ease;
  }

  @media (max-width: 767px) {
    width: 30px;
    > div {
      width: 15px;
      margin: 8px auto;
    }
  }
`

const HambugerLabel = styled.span`
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Phone = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  margin-right: 0.75rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const CTA = styled(Button)`
  margin-top: 0;

  @media (max-width: 1023px) {
    margin-top: 0;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    svg {
      display: none;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
